.header {
    padding-bottom: 2rem;
    z-index: 1;
    .headerlogo{
        width: 200px;
        position: relative;
        &.m{
            width: 2rem;
            height: 100%;
            margin-right: -.3rem;
        }
    }
}
