.vimeoplayer{
    &.loading{
        min-height: 400px;
    }
}

@media screen and (max-width: 576px){
    .vimeoplayer{
        &.loading{
            min-height: 250px;
        }
    }  
}