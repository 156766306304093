.faq{
    position: fixed;
    bottom: 0;
    right: 0;
    transform: translate3d(100%, 0, 0);
    filter: drop-shadow(0 0 .3rem rgba(0,0,0,.5));
    transition: transform .5s ease;
    .icon{
        z-index: 1;
        padding: .5rem;
        border-bottom-left-radius: .5rem;
        border-top-left-radius: .5rem;
        position: absolute;
        left: 0;
        top: 10rem;
        transform: translateX(-100%);
        transition: all .5s ease .4s;
        cursor: pointer;
        svg{
            width: 2.5rem;
            height: 2.5rem;
        }
    }
    .content{
        background-color: white;
        width: 50vw;
        max-width: 500px;
        height: 100vh;
        overflow-y: auto;
        border-left: .6rem solid $primary;
    }
    &[data-open="true"] {
        transform: translate3d(0, 0, 0);
    }
}

@media screen and (max-width: 576px){
    .faq{
        
        .content{
            width: 100vw;
            max-width: 100vw;
            padding-bottom: 7rem;
        }
        .icon{
            top: unset;
            bottom: 2rem;
        }
        &[data-open="true"] {
            .icon{
                transform: translateX(.5rem);
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: .5rem;
                border-top-right-radius: .5rem;
            }
        }
    }
}