.loader{
 position: absolute;
 left: 0;
 top: 0;
 right: 0;
 bottom: 0;
 background-color: rgba(255, 255, 255, 0.8);
 z-index: 500;
 svg{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
 }
}