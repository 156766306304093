.description{
    .downloads{
        &:last-child{
            margin-bottom: 0;
        }
        .card-header{
            svg{
                height: 1.7rem;
                fill: $primary;
                margin-top: -.4rem;
                &.open{
                    transform: rotate(180deg);
                    transform-origin: center center;
                }
            }
        }
        tbody{
            tr:first-of-type{
                td{
                    border-top: 0;
                }
            }
        }
    }
}